<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0 justify-content-center">

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto py-2 shadow-lg rounded"
        >
          <b-link
            :href="SITE_URL"
          >
            <b-img
              :src="appLogoImage"
              fluid
              alt="Responsive image"
              class="center-logo mb-1  px-5"
            />
          </b-link>

          <b-card-title
            title-tag="h4"
            class="font-weight-bold mb-1 text-center "
          >
            Bienvenue sur {{ appName }}
          </b-card-title>

          <!-- form -->
          <validation-observer
            #default="{ invalid }"
            ref="loginValidation"
          >
            <div v-if="step === 1">
              <!-- matricule -->
              <b-form-group
                v-if="step===1"
                label="Matricule"
                label-for="matricule"
              >
                <validation-provider
                  #default="{ errors }"
                  name="matricule"
                  rules="required"
                >
                  <b-form-input
                    id="matricule"
                    v-model="form.matricule"
                    :state="errors.length > 0 ? false:null"
                    name="matricule"
                    autofocus
                    size="sm"
                    placeholder="Entrer votre matricule"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="primary"
                block
                size="sm"
                :disabled="invalid"
                @click.prevent="userMatriculeValided"
              >

                Suivant
                <feather-icon icon="ArrowRightCircleIcon" />
                <b-spinner
                  v-if="loader"
                  variant="light"
                  class="ml-1"
                  type="grow"
                  small
                />
              </b-button>
            </div>
            <div v-if="step === 2 && !firstLogin">
              <b-form-group
                label="Copropriété"
                label-for="copropriete_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="copropriété"
                  rules="required"
                >
                  <v-select
                    id="copropriete_id"
                    v-model="form.copropriete_id"
                    label="name"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="coproprietes"
                    :reduce="(coproprietes) => coproprietes.id"
                    placeholder="Sélectionner une copropriété"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- nouveau mot de passe -->
              <b-form-group
                label="Mot de passe"
                label-for="fpassword"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mot de passe"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="fpassword"
                      v-model="form.password"
                      :type="newPasswordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="fpassword"
                      placeholder="Mot de passe"
                      size="sm"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="newPasswordToggleIcon"
                        @click="toggleNewPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="success"
                block
                size="sm"
                :disabled="invalid"
                @click.prevent="validationStep(4)"
              >
                Se connecter
                <b-spinner
                  v-if="process"
                  variant="light"
                  class="ml-1"
                  type="grow"
                  small
                />
              </b-button>
            </div>
            <div v-if="step===2 && firstLogin">
              <b-form-group
                label="Copropriété"
                label-for="fcopropriete_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="copropriété"
                  rules="required"
                >
                  <v-select
                    id="fcopropriete_id"
                    v-model="form.copropriete_id"
                    label="name"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="coproprietes"
                    :reduce="(coproprietes) => coproprietes.id"
                    placeholder="Sélectionner une copropriété"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="secondary"
                size="sm"
                class="float-left"
                @click.prevent="switchOnStep(1)"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />

                Précédent
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                :disabled="invalid"
                @click.prevent="validationStep(3)"
              >
                Suivant
                <feather-icon icon="ArrowRightCircleIcon" />
              </b-button>
            </div>
            <div v-if="step === 3 && firstLogin">
              <!-- ancien mot de passe -->
              <b-form-group
                label="Ancien mot de passe"
                label-for="old-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ancien mot de passe"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="old-password"
                      v-model="form.old_password"
                      :type="oldPasswordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="old-password"
                      placeholder="Ancien mot de passe"
                      size="sm"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="oldPasswordToggleIcon"
                        @click="toggleOldPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- nouveau mot de passe -->
              <b-form-group
                label="Nouveau mot de passe"
                label-for="new-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nouveau mot de passe"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="form.password"
                      :type="newPasswordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="new-password"
                      placeholder="Nouveau mot de passe"
                      size="sm"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="newPasswordToggleIcon"
                        @click="toggleNewPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- confirmation mot de passe -->
              <b-form-group
                label="Confirmation mot de passe"
                label-for="confirmed-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirmation mot de passe"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="confirmed-password"
                      v-model="form.password_confirmation"
                      :type="confirmedPasswordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="confirmed-password"
                      placeholder="Confirmation mot de passe"
                      size="sm"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="confirmedPasswordToggleIcon"
                        @click="toggleConfirmedPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="secondary"
                size="sm"
                class="float-left"
                @click.prevent="switchOnStep(2)"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Précédent
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="float-right"

                :disabled="invalid"
                @click.prevent="validationStep(4)"
              >
                Se connecter
                <b-spinner
                  v-if="process"
                  variant="light"
                  class="ml-1"
                  type="grow"
                  small
                />
              </b-button>
            </div>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardTitle, BImg, BButton, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { required, password, confirmed } from '@validations'
import vSelect from 'vue-select'

// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { computed, reactive, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/composables/userService'
// eslint-disable-next-line import/no-cycle
import useAuth from '@/composables/authService'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardTitle,
    BImg,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    vSelect,
    BSpinner,

  },
  setup() {
    const { appName, appLogoImage, SITE_URL } = $themeConfig.app
    const sideImg = ref(require('@/assets/images/banner-2.jpg'))
    const {
      getUserByMatricule, user, userCheck, loader,
    } = useUsers()
    const {
      handleUserLogin, process,
    } = useAuth()
    // eslint-disable-next-line no-return-assign
    const imgUrl = computed(() => (store.state.appConfig.layout.skin === 'dark' ? sideImg.value = require('@/assets/images/banner-2.jpg') : sideImg.value))
    const step = ref(1)
    const oldPasswordFieldType = ref('password')
    const newPasswordFieldType = ref('password')
    const confirmedPasswordFieldType = ref('password')
    const oldPasswordToggleIcon = computed(() => (oldPasswordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const newPasswordToggleIcon = computed(() => (newPasswordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const confirmedPasswordToggleIcon = computed(() => (confirmedPasswordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const toggleOldPasswordVisibility = () => {
      oldPasswordFieldType.value = oldPasswordFieldType.value === 'password' ? 'text' : 'password'
    }
    const toggleNewPasswordVisibility = () => {
      newPasswordFieldType.value = newPasswordFieldType.value === 'password' ? 'text' : 'password'
    }
    const toggleConfirmedPasswordVisibility = () => {
      confirmedPasswordFieldType.value = confirmedPasswordFieldType.value === 'password' ? 'text' : 'password'
    }

    const form = reactive({
      matricule: '',
      old_password: '',
      password: '',
      remember_me: false,
      password_confirmation: '',
      copropriete_id: null,
    })
    const switchOnStep = index => {
      step.value = index
    }
    const firstLogin = ref(false)
    const coproprietes = ref([])
    const userMatriculeValided = async () => {
      await getUserByMatricule(form.matricule)
      coproprietes.value = user.value.coproprietes
      firstLogin.value = user.value.first_login
      if (userCheck.value) switchOnStep(2)
    }

    const loginValidation = ref('')
    const validationStep = index => {
      loginValidation.value.validate().then(async success => {
        if (success) {
          form.user_id = user.value.id
          if (index === 2) switchOnStep(index)
          if (index === 3) switchOnStep(index)
          if (index === 4) await handleUserLogin({ ...form })
        }
      })
    }

    return {
      appName,
      SITE_URL,
      process,
      coproprietes,
      loginValidation,
      userMatriculeValided,
      form,
      appLogoImage,
      firstLogin,
      imgUrl,
      required,
      confirmed,
      step,
      switchOnStep,
      password,
      validationStep,
      loader,
      oldPasswordFieldType,
      newPasswordFieldType,
      confirmedPasswordFieldType,
      oldPasswordToggleIcon,
      newPasswordToggleIcon,
      confirmedPasswordToggleIcon,
      toggleOldPasswordVisibility,
      toggleNewPasswordVisibility,
      toggleConfirmedPasswordVisibility,
    }
  },
}
</script>
<style scoped>
.center-logo {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
