var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 bg-white"},[_c('b-row',{staticClass:"auth-inner m-0 justify-content-center"},[_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto py-2 shadow-lg rounded",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-link',{attrs:{"href":_vm.SITE_URL}},[_c('b-img',{staticClass:"center-logo mb-1  px-5",attrs:{"src":_vm.appLogoImage,"fluid":"","alt":"Responsive image"}})],1),_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center ",attrs:{"title-tag":"h4"}},[_vm._v(" Bienvenue sur "+_vm._s(_vm.appName)+" ")]),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.step === 1)?_c('div',[(_vm.step===1)?_c('b-form-group',{attrs:{"label":"Matricule","label-for":"matricule"}},[_c('validation-provider',{attrs:{"name":"matricule","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"matricule","state":errors.length > 0 ? false:null,"name":"matricule","autofocus":"","size":"sm","placeholder":"Entrer votre matricule"},model:{value:(_vm.form.matricule),callback:function ($$v) {_vm.$set(_vm.form, "matricule", $$v)},expression:"form.matricule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.userMatriculeValided.apply(null, arguments)}}},[_vm._v(" Suivant "),_c('feather-icon',{attrs:{"icon":"ArrowRightCircleIcon"}}),(_vm.loader)?_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"light","type":"grow","small":""}}):_vm._e()],1)],1):_vm._e(),(_vm.step === 2 && !_vm.firstLogin)?_c('div',[_c('b-form-group',{attrs:{"label":"Copropriété","label-for":"copropriete_id"}},[_c('validation-provider',{attrs:{"name":"copropriété","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"copropriete_id","label":"name","size":"sm","state":errors.length > 0 ? false : null,"options":_vm.coproprietes,"reduce":function (coproprietes) { return coproprietes.id; },"placeholder":"Sélectionner une copropriété"},model:{value:(_vm.form.copropriete_id),callback:function ($$v) {_vm.$set(_vm.form, "copropriete_id", $$v)},expression:"form.copropriete_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Mot de passe","label-for":"fpassword"}},[_c('validation-provider',{attrs:{"name":"Mot de passe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"fpassword","type":_vm.newPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"fpassword","placeholder":"Mot de passe","size":"sm"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":_vm.toggleNewPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"success","block":"","size":"sm","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.validationStep(4)}}},[_vm._v(" Se connecter "),(_vm.process)?_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"light","type":"grow","small":""}}):_vm._e()],1)],1):_vm._e(),(_vm.step===2 && _vm.firstLogin)?_c('div',[_c('b-form-group',{attrs:{"label":"Copropriété","label-for":"fcopropriete_id"}},[_c('validation-provider',{attrs:{"name":"copropriété","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"fcopropriete_id","label":"name","size":"sm","state":errors.length > 0 ? false : null,"options":_vm.coproprietes,"reduce":function (coproprietes) { return coproprietes.id; },"placeholder":"Sélectionner une copropriété"},model:{value:(_vm.form.copropriete_id),callback:function ($$v) {_vm.$set(_vm.form, "copropriete_id", $$v)},expression:"form.copropriete_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"float-left",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.switchOnStep(1)}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon"}}),_vm._v(" Précédent ")],1),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.validationStep(3)}}},[_vm._v(" Suivant "),_c('feather-icon',{attrs:{"icon":"ArrowRightCircleIcon"}})],1)],1):_vm._e(),(_vm.step === 3 && _vm.firstLogin)?_c('div',[_c('b-form-group',{attrs:{"label":"Ancien mot de passe","label-for":"old-password"}},[_c('validation-provider',{attrs:{"name":"ancien mot de passe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"old-password","type":_vm.oldPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"old-password","placeholder":"Ancien mot de passe","size":"sm"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.oldPasswordToggleIcon},on:{"click":_vm.toggleOldPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nouveau mot de passe","label-for":"new-password"}},[_c('validation-provider',{attrs:{"name":"nouveau mot de passe","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-password","type":_vm.newPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"new-password","placeholder":"Nouveau mot de passe","size":"sm"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":_vm.toggleNewPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirmation mot de passe","label-for":"confirmed-password"}},[_c('validation-provider',{attrs:{"name":"confirmation mot de passe","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmed-password","type":_vm.confirmedPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"confirmed-password","placeholder":"Confirmation mot de passe","size":"sm"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmedPasswordToggleIcon},on:{"click":_vm.toggleConfirmedPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"float-left",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.switchOnStep(2)}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon"}}),_vm._v(" Précédent ")],1),_c('b-button',{staticClass:"float-right",attrs:{"variant":"success","size":"sm","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.validationStep(4)}}},[_vm._v(" Se connecter "),(_vm.process)?_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"light","type":"grow","small":""}}):_vm._e()],1)],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }